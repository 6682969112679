@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Inter', sans-serif;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* ChatBox custom style */

.react-chatbot-kit-chat-container {
    box-shadow: 0px 12px 100px 0px rgba(0, 0, 0, 0.12);
    width: 400px;
    border-radius: 8px;
    overflow: hidden;
}
.react-chatbot-kit-chat-message-container {
    background-color: #fff;
}
.chatbot > div,
.react-chatbot-kit-chat-container{
    width: 100%;
}

.react-chatbot-kit-chat-input-container {
    visibility: hidden;
}

.blog-cont p {
    margin-bottom: 1rem;
}

.react-chatbot-kit-chat-message-container {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem 0.75rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
    display: none;
}

.react-chatbot-kit-chat-inner-container {
    height: auto;
}

.react-chatbot-kit-chat-bot-message {
    width: 100%;
    color: #1F1F37;
    border-radius: 0px 8px 8px 8px;
    margin-left: 4px;
    max-width: 300px;
}

.react-chatbot-kit-chat-bot-message-arrow {
    display: none;
}

.react-chatbot-kit-chat-bot-message-container {
    margin: 4px 0;
}

.chatbot-loader-container * {
    fill: #2860F6
}

@media (max-width: 500px) {
    .react-chatbot-kit-chat-container {
        width: 100%;
    }
}

/* Slider pagination */

span.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    display: block;
    background: #CFE5FF;
    border-radius: 50%;
    cursor: pointer;
}

.swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 6px;
    margin-top: 1rem;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #2860F6;
}

.animation-slide {
    animation-name: slide;
    animation-duration: 0.4s;
}

@keyframes slide {
    from {
        left: -100%;
    }

    to {
        left: 0;
    }
}

.flip-card {
    perspective: 1000px;
}

.flip-card-inner {
    transition: transform 0.5s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}
.flip-card-back {
    transform: rotateY(180deg);
}
.flip-card-front,
.flip-card-back {
    backface-visibility: hidden;
}
.about-card:hover img{
    filter: brightness(0) invert(1);
}
/* Hide up and down arrows on number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

label {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.cardBodyText {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}
.bg-diamond {
    background-image: url(../public/images/hero-bg.png);
    background-position: top right;
    background-repeat: repeat-y;
}


.loader {
    --d: 22px;
    width: 2px;
    height: 2px;
    border-radius: 100%;
    color: #2860F6;
    box-shadow: 
      calc(1.0 * var(--d))      calc(0.0 * var(--d))     0 0,
      calc(0.707 * var(--d))    calc(0.707 * var(--d))  0 1px,
      calc(0.0 * var(--d))      calc(1.0 * var(--d))     0 2px,
      calc(-0.707 * var(--d))   calc(0.707 * var(--d))  0 3px,
      calc(-1.0 * var(--d))     calc(0.0 * var(--d))     0 4px,
      calc(-0.707 * var(--d))   calc(-0.707 * var(--d)) 0 5px,
      calc(0.0 * var(--d))      calc(-1.0 * var(--d))    0 6px;
    animation: l27 1s infinite steps(8);
  }
  
  @keyframes l27 {
    100% {transform: rotate(1turn)}
  }
